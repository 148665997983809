.popupSize {
  padding: 0;
}

.header {
  height: 56px;
  background: #F6F8FD;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--marketplaceColorDark);
}

.selectedValue {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColorDark);
  padding-top: 4px;
}