@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  justify-content: flex-start;
  padding: 0 24px 24px 24px;

  @media (--viewportMedium) {
    padding: 0 36px 36px 36px;
  }
}
