@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 32px;
}

.damageWaiver {
  margin-bottom: 32px;
}

.damageWaiverCheckboxLabel {
  font-weight: bold;
}

.damageWaiverDescription {
  font-size: 14px;
  line-height: 24px;
}

.damageReplacementInput {
  margin-bottom: 24px;
  flex-shrink: 0;
  width: 100%;
}

.hidden {
  visibility: hidden;
}