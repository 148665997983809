@import '../../styles/customMediaQueries.css';

.heroWrapper {
  display: grid;
  grid-template-rows: 160px 63px 110px 190px;
  justify-items: center;
  margin-top: var(--topbarHeightMobile);
  background-color: var(--matterColorDark);
  @media (--viewportMedium) {
    grid-template-rows: 215px 68px 112px 200px;
    margin-top: var(--topbarHeightDesktop);
  }
  @media (--viewportLarge) {
    grid-template-rows: 230px 68px 114px 210px;
  }
}

.heroTitle {
  position: relative;
  display: flex;
  justify-content: center;

  font-family: 'Merriweather';
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;

  margin-top: 90px;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 50px;
    margin-top: 140px;
  }
  @media (--viewportLarge) {
    margin-bottom: 83px;
  }
}

.heroTitle span {
  position: absolute;
  opacity: 0;
}

.heroTitle span {
  animation: fadeEffect 7.5s linear infinite 0s;
  -ms-animation: fadeEffect 7.5s linear infinite 0s;
  -webkit-animation: fadeEffect 7.5s linear infinite 0s;

  &:nth-child(2) {
    animation-delay: 2.5s;
    -ms-animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
  }
  &:nth-child(3) {
    animation-delay: 5s;
    -ms-animation-delay: 5s;
    -webkit-animation-delay: 5s;
  }
}

.heroDescription {
  color: var(--marketplaceColorLight);
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  width: 315px;

  @media (--viewportMedium) {
    width: 440px;
  }
}

.heroButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  height: 50px;
  @media (--viewportMedium) {
  height: 42px;
  }
}

.baseButton {
  min-width: 140px;
  padding-top: 12px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadius);
  &:hover {
    text-decoration: none;
  }
  @media (--viewportMedium) {
    padding-top: 5px;
  }
}

.heroButtonBrowseRentals {
  composes: baseButton;
  background-color: var(--marketplaceColor);
  color: var(--matterColorDark);

  &:hover {
    background-color: var(--matterColorDark);
    color: var(--marketplaceColor);
  }
}

.heroButtonAddItem {
  composes: baseButton;
  background-color: var(--matterColorDark);
  color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorDark);
  }
}

.heroCategories {
  position: relative;
  display: flex;
  justify-content: center;
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  font-size: 48px;
  color: var(--marketplaceColor);
  @media (--viewportMedium) {
    font-size: 58px;
  }
}

/*Push Effect Animation*/
.heroCategories span {
  position: absolute;
  opacity: 0;
}

.heroCategories span {
  animation: leftToRight 30s linear infinite 0s;
  -ms-animation: leftToRight 30s linear infinite 0s;
  -webkit-animation: leftToRight 30s linear infinite 0s;

  @media (--viewportMedium) {
    animation: leftToRightTablet 30s linear infinite 0s;
  }
  @media (--viewportLarge) {
    animation: leftToRightDesktop 30s linear infinite 0s;
  }

  &:nth-child(2) {
    animation-delay: 2.5s;
    -ms-animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
  }
  &:nth-child(3) {
    animation-delay: 5s;
    -ms-animation-delay: 5s;
    -webkit-animation-delay: 5s;
  }
  &:nth-child(4) {
    animation-delay: 7.5s;
    -ms-animation-delay: 7.5s;
    -webkit-animation-delay: 7.5s;
  }
  &:nth-child(5) {
    animation-delay: 10s;
    -ms-animation-delay: 10s;
    -webkit-animation-delay: 10s;
  }
  &:nth-child(6) {
    animation-delay: 12.5s;
    -ms-animation-delay: 12.5s;
    -webkit-animation-delay: 12.5s;
  }
  &:nth-child(7) {
    animation-delay: 15s;
    -ms-animation-delay: 15s;
    -webkit-animation-delay: 15s;
  }
  &:nth-child(8) {
    animation-delay: 17.5s;
    -ms-animation-delay: 17.5s;
    -webkit-animation-delay: 17.5s;
  }
  &:nth-child(9) {
    animation-delay: 20s;
    -ms-animation-delay: 20s;
    -webkit-animation-delay: 20s;
  }
  &:nth-child(10) {
    animation-delay: 22.5s;
    -ms-animation-delay: 22.5s;
    -webkit-animation-delay: 22.5s;
  }
  &:nth-child(11) {
    animation-delay: 25s;
    -ms-animation-delay: 25s;
    -webkit-animation-delay: 25s;
  }
  &:nth-child(12) {
    animation-delay: 27.5s;
    -ms-animation-delay: 27.5s;
    -webkit-animation-delay: 27.5s;
  }
}

@keyframes leftToRight {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 0;
    transform: translateX(-80px);
  }
  5% {
    opacity: 1;
    transform: translateX(0px);
  }
  9% {
    opacity: 1;
    transform: translateX(0px);
  }
  13% {
    opacity: 0;
    transform: translateX(80px);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes leftToRightTablet {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 0;
    transform: translateX(-150px);
  }
  5% {
    opacity: 1;
    transform: translateX(0px);
  }
  9% {
    opacity: 1;
    transform: translateX(0px);
  }
  13% {
    opacity: 0;
    transform: translateX(150px);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes leftToRightDesktop {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 0;
    transform: translateX(-200px);
  }
  5% {
    opacity: 1;
    transform: translateX(0px);
  }
  9% {
    opacity: 1;
    transform: translateX(0px);
  }
  13% {
    opacity: 0;
    transform: translateX(200px);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes leftToRight {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
  }
  5% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  9% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  13% {
    opacity: 0;
    -webkit-transform: translateX(50px);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes leftToRight {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 0;
    -ms-transform: translateX(-50px);
  }
  5% {
    opacity: 1;
    -ms-transform: translateX(0px);
  }
  9% {
    opacity: 1;
    -ms-transform: translateX(0px);
  }
  13% {
    opacity: 0;
    -ms-transform: translateX(50px);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/*FadeIn Animation*/
@keyframes fadeEffect {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -moz-transform: translateY(0px);
  }
  10% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -moz-transform: translateY(0px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeEffect {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: translateY(0px);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(0px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes fadeEffect {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -ms-transform: translateY(0px);
  }
  10% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -ms-transform: translateY(0px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
