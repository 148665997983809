@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

.formContent {
  padding: 34px 25px 48px 25px;
}

.field {
  margin: 0;

  > label {
    font-weight: 400;
    color: var(--marketplaceColorDark);
    padding-top: 0;
    padding-bottom: 0;
  }

  > textarea {
    padding: 0;
    line-height: 20px;
    padding: 3px 0 0 0;
    margin-top: 4px;
  }

  > div {
    font-size: 14px;
  }
}

.fieldWithValue {
  > textarea {
    padding: 2px 0 6px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;
  font-size: 14px;
}

.submitButton {
  margin-top: 36px;
}