@import '../../styles/customMediaQueries.css';

.moochHelpsWrapper {
  display: grid;
  justify-items: center;
  margin-bottom: 40px;
  font-size: 14px;
  color: var(--defaultTextColor);
  margin: 40px 20px;
  @media (--viewportMedium) {
    margin: 0;
  }
}

.moochHelpsTitle {
  font-family: 'Merriweather';
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 900;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    font-size: 40px;
    font-size: 36px;
    line-height: 45px;
  }
}

.moochHelpsDescription {
  margin-top: 4px;
  margin-bottom: 36px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;

  @media (--viewportMedium) {
    width: 440px;
  }
}

.moochHelpsContent {
  display: grid;
  justify-items: center;
  gap: 25px;
}

.forVendorsWrapped,
.forRentersWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 235px 265px 235px;
    grid-template-rows: 70px 180px 40px;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 260px);
    grid-template-rows: 116px 70px;
    gap: 0 30px;
  }
}

.baseItem {
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  & :nth-child(2) {
    width: 260px;
  }

  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    flex-direction: column;
  }
}
/* Vendors */
.forVendors {
  text-align: center;

  & :nth-child(2) {
    text-align: left;
    margin-top: 4px;
  }

  @media (--viewportMedium) {
    row-gap: 10px;
    grid-area: 1/1/1/4;
    margin-bottom: 0;
    & :nth-child(2) {
      text-align: center;
      margin-top: 0;
    }
  }
  @media (--viewportLarge) {
    grid-area: 1/1/1/1;
    text-align: left;
  }
}
.vendorsButtons {
  grid-area: 5/1/5/1;
  @media (--viewportMedium) {
    grid-area: 3/2/3/4;
  }
  @media (--viewportLarge) {
    grid-area: 2/1/2/1;
    align-self: end;
  }
}
.itemVendorsOne {
  composes: baseItem;
  @media (--viewportMedium) {
    grid-area: 2/1/3/2;
  }
  @media (--viewportLarge) {
    grid-area: 1/2/3/2;
  }
}
.itemVendorsTwo {
  composes: baseItem;
  @media (--viewportMedium) {
    grid-area: 2/2/3/3;
  }
  @media (--viewportLarge) {
    grid-area: 1/4/3/3;
  }
}
.itemVendorsThree {
  composes: baseItem;
  @media (--viewportMedium) {
    grid-area: 2/3/3/4;
  }
  @media (--viewportLarge) {
    grid-area: 1/5/3/4;
  }
}
/* Renters */
.forRenters {
  text-align: center;
  grid-area: 1/1/1/1;

  & :nth-child(2) {
    text-align: left;
    margin-top: 4px;
  }

  @media (--viewportMedium) {
    grid-area: 1/1/2/4;
    margin-bottom: 0;
    & :nth-child(2) {
      text-align: center;
      margin-top: 0;
    }
  }
  @media (--viewportLarge) {
    grid-area: 1/5/2/4;
  }
}
.rentersButtons {
  @media (--viewportMedium) {
    grid-area: 3/2/3/3;
  }
  @media (--viewportLarge) {
    grid-area: 2/5/3/4;
  }
}
.itemRentersOne {
  composes: baseItem;
  @media (--viewportMedium) {
    grid-area: 2/2/3/1;
  }
  @media (--viewportLarge) {
    grid-area: 1/2/3/1;
  }
}
.itemRentersTwo {
  composes: baseItem;
  @media (--viewportMedium) {
    grid-area: 2/2/3/2;
  }
  @media (--viewportLarge) {
    grid-area: 1/2/3/2;
  }
}
.itemRentersThree {
  composes: baseItem;
  @media (--viewportMedium) {
    grid-area: 2/3/3/4;
  }
  @media (--viewportLarge) {
    grid-area: 1/4/3/3;
  }
}

.vendorsButtons,
.rentersButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  height: 40px;

  & a:first-child {
    background-color: var(--marketplaceColor);
    &:hover {
      color: var(--marketplaceColor);
      background-color: var(--marketplaceColorDark);
      border-color: var(--marketplaceColorDark);
    }
  }
  & a:nth-child(2) {
    background-color: var(--matterColorLight);
    &:hover {
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadius);
  color: var(--marketplaceColorDark);
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  width: 160px;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    width: 140px;
  }
}

.forVendors,
.forRenters {
  display: flex;
  flex-direction: column;

  & :first-child {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: var(--marketplaceColorDark);
  }
  & :nth-child(2) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  @media (--viewportMedium) {
    & :first-child {
      font-family: 'Merriweather';
      text-transform: none;
      font-size: 28px;
      font-weight: 900;
      line-height: 32px;
    }
  }
}

.forVendors {
  @media (--viewportMedium) {
    align-self: flex-end;
  }
}

.forRenters {
  align-self: center;
}

.icon {
  max-width: 56px;
  height: 56px;
  color: var(--marketplaceColorDark);
  margin-right: 18px;
  color: var(--marketplaceColor);

  @media (--viewportLarge) {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.logoWrapper {
  background-image: url(logo.svg);
  width: 162px;
  height: 138px;
  position: relative;

  @media (--viewportLarge) {
    background-image: url(bigLogo.svg);
    width: 970px;
    height: 188px;
  }
}

.iconLogo {
  width: 72px;
  height: 65px;
  position: absolute;
  top: 37px;
  left: 36px;
  @media (--viewportLarge) {
    width: auto;
    height: auto;
    left: 422px;
  }
}

.logo {
  pointer-events: none;
}
