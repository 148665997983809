@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.columnGrid {
  padding: 12px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 9px;
    padding: 16px 24px;
  }

  @media (--viewportLarge) {
    padding: 16px 24px;
  }
}

.listingCards {
  composes: columnGrid;

  @media (--viewportLarge) {
    width: 54.5vw;
  }
}

.listingCardsWithoutMap {
  composes: columnGrid;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.listingCard {
  margin-bottom: 24px;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    /* flex-basis: calc(50% - 12px); */
    /* margin-right: 24px; */
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    width: 54.5vw;
    padding: 0 36px;
  }
}

.noSearchResults {
  height: calc(100vh - var(--topbarHeightDesktop) - 68px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--viewportLarge) {
    width: 55vw;
  }
}

.noSearchResultsWithoutMap {
  @media (--viewportLarge) {
    width: 100vw;
  }
}

.noSearchResultsIcon {
  position: relative;
}

.searchIcon {
  width: 36px;
  height: 36px;
}

.closeIcon {
  position: absolute;
  top: 9px;
  left: 9px;
  width: 10px;
  height: 10px;
}

.mapIcon {
  composes: marketplaceTinyFontStyles from global;

  position: fixed;
  bottom: 42px;
  left: calc(50vw - 60px); /* 50 screen width - half of the button width  */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--marketplaceColorDark);
  background-color: var(--marketplaceColor);
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;

  width: 120px;
  height: 40px;
  @media (--viewportLarge) {
    display: none;
  }

  :hover {
    cursor: pointer;
  }
}

.icon {
  width: 16px;
  height: 16px;
}

.mobileSorting {
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;

  @media (--viewportMedium) {
    display: none;
  }
}

.foundResults {
  padding-right: 14px;
}
