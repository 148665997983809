@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.categoryQuantityWrapper {
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
  }
}

.category {
  composes: formMargins;
}

.quantity {
  composes: formMargins;
}
