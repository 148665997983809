@import '../../styles/customMediaQueries.css';

.sectionWrapper {
  display: grid;
  place-items: center;
  margin: 0;

  @media (--viewportMedium) {
    margin: 80px 0;
  }
}

.sectionTitle {
  margin-bottom: 12px;
  text-align: center;
  font-family: 'Merriweather';
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 45px;
  }
}

.sectionDescription {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 310px;
  color: var(--defaultTextColor);
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 28px;
    width: 80%;
  }
}

.baseGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-self: center;

  & > *:first-child {
    border-top-left-radius: 8px;
    border-top: 1px solid var(--rentalCategoriesDefaultColor);
  }
  & > *:nth-child(2) {
    border-top-right-radius: 8px;
    border-top: 1px solid var(--rentalCategoriesDefaultColor);
  }
  & > *:nth-last-child(2) {
    border-bottom-left-radius: 8px;
  }
  & > *:last-child {
    border-bottom-right-radius: 8px;
  }

  @media (--viewportMedium) {
    gap: 8px;
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
  }
}

.eventsBlocksWrapper {
  composes: baseGrid;
}

.baseItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.eventsBlocksItem {
  composes: baseItem;
  width: 170px;
  height: 172px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorLight);
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }

  &:hover img {
    transform: scale(1.1);
  }

  @media (--viewportMedium) {
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    width: 340px;
    height: 224px;
    font-size: 20px;
    line-height: 25px;
  }
  @media (--viewportLarge) {
    width: 276px;
    height: 288px;
  }
}

.eventsBlocksPicture {
  margin-bottom: 10px;
  width: 134px;
  height: 122px;
  pointer-events: none;
  transition: transform 0.5s;

  @media (--viewportMedium) {
    margin-bottom: 8px;
    width: 177px;
    height: 159px;
  }
  @media (--viewportLarge) {
    margin-bottom: 20px;
    width: 220px;
    height: 200px;
  }
}

.eventsGridWrapper {
  composes: baseGrid;
  margin: 12px 12px 40px 12px;
}

.eventGridItemBase {
  composes: baseItem;
  flex-direction: row;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  width: 170px;
  height: 65px;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    border: 1px solid var(--rentalCategoriesDefaultColor);
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    text-align: center;
    width: 340px;
    height: 120px;

    &:hover {
      border-color: var(--marketplaceColor);
      text-decoration: none;
      & > *:first-child {
        color: var(--marketplaceColor);
      }
    }
  }
  @media (--viewportLarge) {
    width: 276px;
    height: 136px;
  }
}

.oddEventGridItem {
  composes: eventGridItemBase;
  border-left: 1px solid var(--rentalCategoriesDefaultColor);
  border-right: 1px solid var(--rentalCategoriesDefaultColor);
  border-bottom: 1px solid var(--rentalCategoriesDefaultColor);
}

.evenEventGridItem {
  composes: eventGridItemBase;
  border-right: 1px solid var(--rentalCategoriesDefaultColor);
  border-bottom: 1px solid var(--rentalCategoriesDefaultColor);
}

.eventsGridIcon {
  width: 32px;
  height: 32px;
  margin-left: 12px;
  margin-right: 8px;
  color: var(--rentalCategoriesDefaultColor);

  &:hover {
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
    width: 48px;
    height: 48px;
  }
}
