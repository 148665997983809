@import '../../styles/customMediaQueries.css';

.categoriesWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-self: center;
  margin: 0 12px 80px 12px;

  & > *:first-child {
    border-top-left-radius: 8px;
    border-top: 1px solid var(--rentalCategoriesDefaultColor);
  }
  & > *:nth-child(2) {
    border-top-right-radius: 8px;
    border-top: 1px solid var(--rentalCategoriesDefaultColor);
  }
  & > *:nth-last-child(2) {
    border-bottom-left-radius: 8px;
  }
  & > *:last-child {
    border-bottom-right-radius: 8px;
    border-right: 1px solid var(--rentalCategoriesDefaultColor);
    border-bottom: 1px solid var(--rentalCategoriesDefaultColor);
    @media (--viewportMedium) {
      border: none;
    }
  }

  @media (--viewportMedium) {
    margin: 0 70px 80px 70px;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    border-radius: 0;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.categoriesTitle {
  margin-top: 28px;
  margin-bottom: 12px;
  text-align: center;
  font-size: 14px;
  font-weight: 800;
}
