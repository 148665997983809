.logoMobile {
  width: 190px;
  height: 24px;
}

.logoPolygon {
  position: relative;
  display: flex;
  justify-content: center;
}

.polygon {
  width: 64px;
  height: 72px;
  color: var(--marketplaceColor);
}

.letter {
  position: absolute;
  top: 24px;
  width: 37px;
  height: 21px;
  color: var(--marketplaceColorDark);
}
.icon {
  width: 87px;
  height: 71px;
}
