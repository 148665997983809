@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
}

.mapRoot {
  width: 100%;
  height: 100%;
}

.reusableMap {
  width: 100%;
  height: 100%;
}

.defaultMapLayout {
  position: fixed;
  top: 0;
  right: 0;
  width: 50vw;
  height: 100vh;
}

/**
 * When reusable map is attached right to the body it's hidden.
 * Specificity rule is added to overwrite positioning coming from props.className
 */
body > .reusableMapHidden {
  position: absolute;
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  opacity: 0;

  @media (--viewportMedium) {
    top: -1000px;
    left: -1000px;
    right: auto;
  }
}

.mapIcon {
  composes: marketplaceTinyFontStyles from global;

  position: fixed;
  bottom: 24px;
  left: calc(50vw - 60px); /* 50 screen width - half of the button width  */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--marketplaceColorDark);
  background-color: var(--marketplaceColor);
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;

  width: 120px;
  height: 40px;
  @media (--viewportLarge) {
    display: none;
  }

  & :hover {
    cursor: pointer;
  }
}

.icon {
  width: 16px;
  height: 16px;
}
