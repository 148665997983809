@import '../../styles/customMediaQueries.css';

.testimonialsWrapper {
  display: flex;
  justify-content: center;
  margin: 40px 13px;
  @media (--viewportMedium) {
    margin: 65px 70px;
  }
}

.contentWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  @media (--viewportMedium) {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 324px 42px;
    row-gap: 24px;
    justify-items: center;
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 63px 355px 63px;
    column-gap: 30px;
    row-gap: 0;
    width: 1140px;
    align-items: end;
  }
}

.testimonialsImageWrapper {
  display: none;
  overflow: hidden;

  &:hover img {
    transform: scale(1.1);
  }

  @media (--viewportLarge) {
    grid-area: 1/5/4/1;
    display: flex;
    border-radius: 8px;
  }
}

.testimonialImage {
  pointer-events: none;
  transition: opacity 0.1s linear 0.015s;
  transition: transform 0.5s;
}

.cacheImages {
  width: 0px;
  height: 0px;
  overflow: hidden;
  opacity: 0;
}

.testimonialImageAnimate {
  opacity: 0;
}

.valueWrapper {
  position: relative;
  padding: 30px 8px 30px 16px;
  display: flex;
  flex-direction: column;
  background-color: var(--marketplaceColor);
  border-radius: 12px;
  color: var(--marketplaceColorDark);
  height: 100%;
  @media (--viewportMedium) {
    padding: 32px 32px 32px 85px;
    grid-area: 1/1/1/9;
  }
  @media (--viewportLarge) {
    grid-area: 2/4/3/9;
  }
}

.icon {
  position: absolute;
  top: -15px;
  left: 18px;
  width: 37px;
  height: 32px;
  color: var(--marketplaceColorDark);
  @media (--viewportMedium) {
    top: 64px;
    left: 32px;
  }
}

.author {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.value {
  font-family: 'Merriweather';
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin-top: 4px;
  margin-bottom: 48px;
  height: 235px;
  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 33px;
    height: 100%;
    margin-bottom: 0;
  }
}

.whoIs {
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.buttonsWrapper {
  display: none;
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(2, 50px);
    grid-template-rows: 42px;
    gap: 12px;
    grid-area: 2/4/3/6;
  }
  @media (--viewportLarge) {
    grid-area: 5/5/3/5;
  }
}

.backButton,
.forwardButton {
  border: none;
  position: relative;
  cursor: pointer;
  &:hover {
    .polygon {
      color: #ecdb00;
    }
    .arrow {
      color: #000000;
    }
  }
}

.forwardButton {
  .arrow {
    transform: rotate(180deg);
  }
}

.polygon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 42px;
  color: var(--marketplaceColor);
}

.arrow {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
  width: 18px;
  height: 13px;
  color: var(--marketplaceColorDark);
}

.bubblesWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (--viewportMedium) {
    display: none;
  }
}

.bubble {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: var(--marketplaceColor);
  border: 2px solid var(--marketplaceColorDark);
}

.bubbleSelected {
  composes: bubble;
  border: 2px solid var(--matterColorLight);
}
