@import '../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 248px;
    padding-left: 24px;
    min-height: 40vh;
    box-shadow: var(--boxShadowPopup);
    border-radius: 4px;
  }
}

.authenticated,
.notAuthenticated,
.authenticatedShowAdditional {
  composes: root;
}

.authenticated {
  @media (--viewportMedium) {
    min-height: 30vh;
  }
}

.notAuthenticated {
  @media (--viewportMedium) {
    min-height: 19vh;
  }
}

.authenticatedShowAdditional {
  @media (--viewportMedium) {
    min-height: 38vh;
  }
}

.content,
.settings {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  margin: 50px 28px 16px 28px;
  @media (--viewportMedium) {
    gap: 26px;
    margin: 22px 20px 16px 20px;
  }
}

.settings {
  margin: -16px 28px 0 28px;
  @media (--viewportMedium) {
    margin: 0 20px 0 20px;
  }
}

.link,
.addLink,
.howMoochWorksLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColorDark);
  font-weight: 300;
  font-size: 20px;
  @media (--viewportMedium) {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}

.addLink,
.howMoochWorksLink {
  display: flex;
  align-items: center;
}

.addLink {
  gap: 17px;
  margin-left: -37px;
  @media (--viewportMedium) {
    gap: 12px;
    margin-left: -28px;
  }
}

.howMoochWorksLink {
  gap: 11px;
}

.addIcon {
  width: 22px;
  height: 22px;
  color: var(--marketplaceColorDark);
  @media (--viewportMedium) {
    width: 16px;
    height: 16px;
  }
}

.arrowIcon {
  width: 10px;
  height: 6px;
  color: var(--marketplaceColorDark);
}

.spacer {
  border-top: 1px solid #eeefef;
}
