@import '../../styles/customMediaQueries.css';

.menuLabel {
  font-size: 14px;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  padding: 0 10px;

  white-space: nowrap;
}

.menuLabelValue {
  font-weight: 800;
}

.arrowIcon {
  width: 10px;
  height: 16px;
  margin-left: 4px;
}

.menuContent {
  padding: 10px 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 40px;
  max-width: 220px;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--marketplaceColorDark);
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  line-height: 20px;
  padding: 6px 24px;
  cursor: pointer;
  border: 0;
  transition: var(--transitionStyleButton);
  width: 100%;

  &:hover {
    background: var(--marketplaceColor);
  }
}

.menuItemSelected {
  background: var(--marketplaceColor);
}
