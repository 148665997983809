@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  composes: h4 from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  padding-bottom: 164px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
    padding-bottom: 0;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.selectQuantity {
  margin: 20px 20px 30px 20px;
  @media (--viewportMedium) {
    margin: 0 0 20px 0;
  }
}

.selectQuantityText {
  composes: h5 from global;
  margin-bottom: 0;
}

.deliveryWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--successColorLight);
  border: 1px solid var(--marketplaceColor);
  border-radius: 8px;
  padding: 11px;
  margin: 20px;
  @media (--viewportMedium) {
    margin: 30px 0 40px -12px;
  }
}

.deliveryOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.deliveryLabel {
  font-size: 16px;
}

.deliveryFee,
.pickupAddress {
  font-size: 14px;
}
