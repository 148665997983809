.root {
  outline: none;
}

.contentWrapper {
  margin-bottom: 24px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background: var(--marketplaceColorDark);
  padding: 0 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.clearButton {
  display: flex;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  color: var(--marketplaceColor);
  cursor: pointer;
  outline: none;
  border: none;
}

.applyCancelButtons {
  display: flex;
  flex-direction: row;
}

.applyButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  height: 32px;
  min-height: 32px;
  font-size: 14px;
  padding: 0 16px;
  border: 1px solid var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
  }
}

.cancelButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  height: 32px;
  min-height: 32px;
  font-size: 14px;
  padding: 0 16px;
  margin-right: 8px;
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColorDark);
  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColorDark);
    background-color: var(--marketplaceColor);
  }
}
