@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.columnGrid {
  display: grid;
  gap: 70px;
  @media (--viewportMedium) {
    gap: 22px;
  }
}

.deliveryType {
  composes: formMargins;
  font-size: 16px;
}

.detailsWrapper {
  composes: columnGrid;
  margin-bottom: 22px;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.deliveryFee {
  composes: formMargins;
}

.stateZipWrapper {
  composes: columnGrid;
  column-gap: 22px;
  grid-template-columns: repeat(2, 1fr);
}

.street,
.city,
.state,
.zip {
  composes: formMargins;
  flex-shrink: 0;
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
