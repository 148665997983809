@import '../../styles/customMediaQueries.css';

.rootBase {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 40px 40px 0 40px;

  background-color: var(--footerBackgroundColor);

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 16px 0;
    border-top: none;
  }
}

.root {
  composes: rootBase;
}

.callToActionsRoot {
  composes: rootBase;
  padding: 160px 40px 0 40px;
  @media (--viewportMedium) {
    padding: 170px 24px 33px 24px;
  }
  @media (--viewportLarge) {
    padding: 0 0 33px 0;
  }
}

.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--rentalCategoriesDefaultColor);
  }
}

.topBorderForCallToActions {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    margin-top: -280px;
    padding-top: 300px;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--rentalCategoriesDefaultColor);
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    gap: 20px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.basedList {
  display: flex;
  flex-direction: column;
}

.listWrapper {
  composes: basedList;
}

.eventsAndCategories {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.eventsWrapper,
.categoriesWrapper {
  composes: basedList;
  margin-bottom: 36px;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.events {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 50px;

  @media (--viewportMedium) {
    grid-column-gap: 70px;
  }
  @media (--viewportLarge) {
    grid-column-gap: 90px;
  }
}
.categories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 50px;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 70px;
  }
  @media (--viewportLarge) {
    grid-column-gap: 90px;
  }
}

.header {
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: var(--marketplaceColorDark);
  margin-bottom: 12px;
}

.baseLink {
  composes: h5 from global;
  line-height: 20px;
  color: var(--defaultTextColor);
  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}

.link {
  composes: baseLink;
}

.linkWithIcon {
  composes: baseLink;
  display: flex;
  align-items: center;
  margin-left: -24px;
}

.iconLink {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.logoWrapper {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    max-width: 320px;
  }
  @media (--viewportLarge) {
    flex-direction: column;
  }
}

.iconLogo {
  width: 36px;
  height: 32px;
}

.letterLogo {
  position: relative;
  display: flex;
  min-width: 32px;
  height: 32px;
  background-color: var(--marketplaceColorDark);
  border-radius: 2px;

  .logo {
    position: absolute;
    top: 8px;
    left: 5px;
    width: 23px;
    height: 14px;
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    width: 32px;
    margin-bottom: 4px;
    margin-top: 0;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 12px;
  color: var(--defaultTextColor);
  @media (--viewportMedium) {
    margin-bottom: 14px;
  }
  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.socialMedia {
  display: grid;
  grid-template-columns: repeat(3, 36px);
  grid-template-rows: repeat(2, 36px);
  gap: 12px;

  @media (--viewportMedium) {
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 36px);
    grid-template-rows: 36px;
  }
}

.polygonLink {
  position: relative;
  width: 36px;
  height: 31px;

  &:hover {
    .polygon {
      color: var(--marketplaceColor);
    }
    .mediaIcon {
      color: var(--marketplaceColorDark);
    }
  }
}

.polygon {
  position: absolute;
  width: 36px;
  height: 31px;
  color: var(--marketplaceColorDark);
}

.mediaIcon {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 9px;
  left: 11px;
  color: var(--marketplaceColor);
}

.links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 50px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
  }
  @media (--viewportLarge) {
    grid-template-columns: 220px 294px 260px 290px;
    grid-template-rows: 1fr;
    column-gap: 0;
  }
}

.moochRentersVendors {
  grid-area: 1/1/1/1;
}

.forRenters {
  grid-area: 2/2/2/2;
  @media (--viewportMedium) {
    grid-area: 1/2/1/2;
  }
  @media (--viewportLarge) {
    grid-area: 1/2/1/3;
  }
}

.forVendors {
  grid-area: 2/1/2/1;
  @media (--viewportMedium) {
    grid-area: 3/3/1/4;
  }
  @media (--viewportLarge) {
    grid-area: 1/3/1/4;
  }
}

.moochInfo {
  align-items: center;
  grid-area: 1/2/2/2;
  display: flex;

  @media (--viewportMedium) {
    grid-area: 2/1/2/4;
    border-top: 1px solid var(--rentalCategoriesDefaultColor);
    margin-top: 20px;
    padding-top: 20px;
    justify-content: space-between;
  }
  @media (--viewportLarge) {
    grid-area: 1/4/1/5;
    flex-direction: column;
    align-items: flex-start;
    border-top: 0;
    margin-top: 0;
  }
}
