.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  & + label .box {
    stroke: var(--marketplaceColorDark);
    fill: var(--matterColorLight);
  }

  /* Hide default box if checked */
  &:checked + label .box {
    display: none;
  }

  /* Highlight the borders if the checkbox is hovered */
  &:hover + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  & + label .box.success,
  &:hover + label .box.success {
    stroke: var(--successColor);
  }

  & + label .checkedBox {
    fill: var(--marketplaceColor);
  }
  & + label .checkMark {
    fill: var(--marketplaceColorDark);
  }

  /* Hide checked box and checkmark by default */
  & + label .checkedBox,
  & + label .checkMark {
    display: none;
  }

  /* Display checked box and checkmark when checked */
  &:checked + label .checkedBox,
  &:checked + label .checkMark {
    display: inline;
  }

  /* successColor version */
  &:checked + label .checkedBox.success {
    fill: var(--successColor);
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColorDark);
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-right: 8px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.text {
}
.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--matterColor);
  cursor: pointer;
}
