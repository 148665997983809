@import '../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--matterColorDark);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 26px 0 0;
  }
}

.logoSearchWrapper {
  display: flex;
  align-items: center;
}

.allRentalsButton {
  display: flex;
  align-items: center;

  transition: var(--transitionStyleButton);
  background-color: var(--matterColorAnti);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  letter-spacing: 0;
  color: var(--marketplaceColorLight);
  padding: 8px 12px;
  border-radius: var(--borderRadius);
  text-decoration: none;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;

  flex-shrink: 0;
  /* padding: 21px 24px 21px 24px; */
  padding: 10px 12px 3px 12px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 15px 12px 7px 12px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 42px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 234px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  margin-right: 12px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  transition: var(--transitionStyleButton);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  flex-shrink: 0;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColorLight);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.inboxIcon {
  width: 24px;
  height: 22px;
}

.addIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.arrowIcon {
  width: 10px;
  height: 16px;
  margin-left: 4px;
}

.favoritesIcon {
  width: 22px;
  height: 20px;
  margin-left: 17px;
  margin-right: 17px;
}

.rightButtonsSettings {
  transition: var(--transitionStyleButton);
  padding: 0 12px 0 12px;
  color: var(--marketplaceColorLight);
}

.createListing {
  display: flex;
  &:first-child {
    margin-right: 4px;
  }
}

.yourListingsLink {
  transition: var(--transitionStyleButton);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  padding: 0 12px 0 12px;
  color: var(--marketplaceColorLight);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.favoritesLink {
  color: var(--marketplaceColorLight);
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Inbox */
.inboxLink {
  color: var(--marketplaceColorLight);
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.inbox {
  position: relative;
  margin-right: 10px;
}

.notificationDot {
  /* Dimensions */
  width: 16px;
  height: 16px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: -6px;
  right: -7px;

  /* Style: notification dot */
  background-color: var(--marketplaceColor);
  border: 4px solid var(--marketplaceColorDark);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  transition: var(--transitionStyleButton);
  height: 100%;
  padding: 0 12px;

  &:hover {
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.menuContent {
  top: 50px;
  width: 187px;
  min-width: 276px;
  padding: 10px 0;
  border-radius: var(--borderRadius);
}

.profileMenuContent {
  composes: menuContent;
}

.howMoochWorksMenuContent {
  composes: menuContent;
  top: 42px;
}

.rightButtonsWrapper {
  display: flex;
  align-items: center;
  margin-top: 18px;
}

/* Authentication */
.signupLink {
  composes: rightButtonsSettings;
  border: 1px solid var(--marketplaceColor);
  margin-left: 4px;
  border-radius: var(--borderRadius);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorDark);
    border: 1px solid var(--marketplaceColor);
    text-decoration: none;
  }
}

.loginLink {
  composes: rightButtonsSettings;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.signup,
.login {
  composes: h5 from global;
}

.profileSettingsLink,
.accountSettingsLink {
  composes: h4 from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--marketplaceColorDark);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColorDark);
    background-color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  height: 32px;
  margin: 10px 0 0 0;
  padding: 8px 24px 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;

  &:hover {
    cursor: pointer;
    color: var(--matterColorDark);
    background-color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
    margin-bottom: 6px;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;
}
