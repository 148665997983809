@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--marketplaceColorDark);
}

.loupeIcon {
  width: 14px;
  height: 14px;
}

.input {
  flex-grow: 1;
  height: var(--LocationAutocompleteInput_inputHeight);
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.cancel {
  composes: h5 from global;
  margin: 16px 12px;
  color: var(--marketplaceColor);

  &:hover {
    cursor: pointer;
  }
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  left: 0;
  z-index: calc(var(--zIndexPopup) + 1);
}

/* List of predictions, with a responsive padding size */
.predictions {
  composes: marketplaceBodyFontStyles from global;
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadius);
  background-color: var(--matterColorLight);

  margin: 10px 0 0 0;
  padding: 14px 0;
  @media (--viewportMedium) {
    margin: -7px 0 0 0;
  }

  & li {
    color: var(--marketplaceColorDark);
    transition: var(--transitionStyleButton);
    line-height: 24px;
    margin: 20px 0 0 0;
    padding: 9px 24px;

    @media (--viewportMedium) {
      color: var(--marketplaceColorDark);
      padding: 10px var(--LocationAutocompleteInput_sidePaddingDesktop);
      margin: 0;
    }

    &:hover,
    &.highlighted {
      cursor: pointer;
      background-color: var(--marketplaceColor);
    }
  }
}
