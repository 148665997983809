@import '../../styles/customMediaQueries.css';

.mobileInputRoot {
  height: var(--topbarHeightMobile);
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.iconBase {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;

  background-color: var(--marketplaceColor);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}

.desktopIcon {
  composes: iconBase;
  height: calc(var(--TopbarSearchForm_inputHeight) - 2px);
}

.mobileIcon {
  composes: iconBase;
  height: var(--TopbarSearchForm_inputHeight);
}

.mobileInput,
.desktopInput {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  background-color: var(--matterColorLight);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: var(--inputTextColor);

  width: var(--TopbarSearchForm_inputWidth);
  height: calc(var(--TopbarSearchForm_inputHeight) - 2px);
  margin: 10px 0 10px 12px;

  line-height: unset;
  border: none;
  padding: 0 0 0 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-top: 1px solid var(--marketplaceColor);
    border-bottom: 1px solid var(--marketplaceColor);
    border-left: 1px solid var(--marketplaceColor);
    outline: none;
    padding: 0 0 0 9px;
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin: 18px 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.mobileInput {
  composes: h5 from global;
  margin: 10px 0 10px 12px;
  width: 100%;
  height: var(--TopbarSearchForm_inputHeight);
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  margin: 16px 12px;
  width: 94%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 40px;
  left: 0;
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
}
