.toggleWrapper {
  display: flex;
  align-items: center
}

.toggleLabel {
  margin: 0 6px 0 16px;
  font-size: 14px
}

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
}

.toggleSwitch input[type='checkbox'] {
  display: none;
}

.toggleSwitch .switch {
  position: absolute;
  cursor: pointer;
  border: 1px solid var(--matterColorDark);
  background-color: var(--matterColorLight);
  border-radius: 12px;
  top: -1px;
  right: 0;
  bottom: -1px;
  left: 0;
  transition: background-color 0.2s ease;
}

.toggleSwitch .switch::before {
  position: absolute;
  content: '';
  left: 2px;
  top: 2px;
  width: 14px;
  height: 14px;
  background-color: var(--matterColorDark);
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggleSwitch input[type='checkbox']:checked + .switch::before {
  transform: translateX(13px);
  background-color: var(--matterColorDark);
}

.toggleSwitch input[type='checkbox']:checked + .switch {
  background-color: var(--matterColorLight);
}
