@import '../../styles/customMediaQueries.css';

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    height: 65px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
  }
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    min-height: 64px;
    width: 241px;
  }
}

.buttonToListings {
  composes: buttonFont buttonText buttonBorders buttonColors from global;
  min-height: 40px;
  padding-top: 9px;
  margin-bottom: 24px;
  @media (--viewportMedium) {
    display: block;
    width: 241px;
    min-height: 63px;
    padding-top: 20px;
  }
}
