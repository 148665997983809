@import '../../styles/customMediaQueries.css';

.baseItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  width: 165px;
  height: 60px;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    border: 1px solid var(--rentalCategoriesDefaultColor);
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    text-align: center;
    width: 134px;
    height: 130px;

    &:hover {
      border-color: var(--marketplaceColor);
      text-decoration: none;
      & > *:first-child {
        color: var(--marketplaceColor);
      }
    }
  }
}

.oddCategoryItem {
  composes: baseItem;
  border-left: 1px solid var(--rentalCategoriesDefaultColor);
  border-right: 1px solid var(--rentalCategoriesDefaultColor);
  border-bottom: 1px solid var(--rentalCategoriesDefaultColor);
}

.evenCategoryItem {
  composes: baseItem;
  border-right: 1px solid var(--rentalCategoriesDefaultColor);
  border-bottom: 1px solid var(--rentalCategoriesDefaultColor);
}

.categoryIcon {
  width: 32px;
  height: 32px;
  margin-left: 12px;
  margin-right: 8px;
  color: var(--rentalCategoriesDefaultColor);

  &:hover {
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
    width: 48px;
    height: 48px;
  }
}

.allCategoriesLink {
  composes: baseItem;
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  color: var(--marketplaceColorDark);

  &:hover {
    border-color: var(--marketplaceColor);
    & > *:first-child {
      color: var(--marketplaceColorDark);
    }
  }

  & > *:first-child {
    color: var(--marketplaceColorDark);
  }
}
