@import '../../styles/customMediaQueries.css';

.sectionWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0 0;
  overflow: hidden;
}

.sectionContent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 375px;
  height: 495px;

  @media (--viewportMedium) {
    width: 774px;
    height: 356px;
  }
  @media (--viewportLarge) {
    width: 1140px;
    height: 492px;
  }
}

.imageWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -205px;
  right: -160px;

  @media (--viewportMedium) {
    left: 0;
    right: 0;
  }
  @media (--viewportLarge) {
  }
}

.image {
  width: 155%;
  height: 100%;
  pointer-events: none;

  @media (--viewportMedium) {
    width: 100%;
  }
}

.contentWrapper {
  position: relative;
  display: grid;
  grid-template-rows: 75px 55px 80px 85px;
  justify-content: center;
  margin-top: -50px;

  @media (--viewportMedium) {
    grid-template-rows: 80px 36px 50px 94px;
    margin-top: -53px;
  }
  @media (--viewportLarge) {
    grid-template-columns: 688px;
    grid-template-rows: 100px 50px 50px 100px;
  }
}

.iconLogo {
  width: 72px;
  height: 62px;
  justify-self: center;
  margin-top: 16px;
  @media (--viewportMedium) {
    width: 62px;
    height: 52px;
    margin-top: 30px;
  }
  @media (--viewportLarge) {
    margin-top: 34px;
  }
}

.logo {
  width: 40px;
  height: 24px;
  color: var(--marketplaceColor);
  justify-self: center;
  margin-top: 36px;
  @media (--viewportMedium) {
    margin-top: 48px;
  }
  @media (--viewportLarge) {
    margin-top: 57px;
  }
}

.title {
  font-size: 21px;
  font-weight: 900;
  line-height: 40px;
  color: var(--matterColorLight);
  text-align: center;
  @media (--viewportMedium) {
    font-size: 26px;
  }
  @media (--viewportLarge) {
    font-size: 32px;
  }
}

.description {
  color: var(--marketplaceColorLight);
  justify-self: center;
  text-align: center;
  width: 315px;

  @media (--viewportMedium) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 432px;
  }
}

.button {
  height: 40px;
  padding-top: 7px;
  text-align: center;
  white-space: nowrap;

  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadius);

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding-top: 3px;
  }
}

.buttonsWrapper {
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(2, 140px);
  justify-content: center;
  gap: 12px;

  & > *:first-child {
    text-align: center;
    background-color: var(--marketplaceColor);
    color: var(--matterColorDark);
    &:hover {
      background-color: var(--matterColorDark);
      color: var(--marketplaceColor);
    }
  }

  & > *:nth-child(2) {
    text-align: center;
    background-color: var(--matterColorDark);
    color: var(--marketplaceColor);
    &:hover {
      background-color: var(--marketplaceColor);
      color: var(--matterColorDark);
    }
  }
}

.signUpButtonsWrapper {
  justify-self: center;
  width: 140px;
  height: 40px;
  padding-top: 7px;
  text-align: center;
  background-color: var(--marketplaceColor);
  color: var(--matterColorDark);

  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadius);

  &:hover {
    text-decoration: none;
    background-color: var(--matterColorDark);
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    padding-top: 3px;
  }
}
