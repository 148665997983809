.root {
}

.password {
  margin-top: 2px;
  margin-bottom: 36px;

  > label {
    color: var(--marketplaceColorDark);
    font-weight: 400;
  }

  > div {
    margin-bottom: 0;
  }
}
