@import '../../styles/customMediaQueries.css';

.root {
  position: fixed;
  width: 100%;
  z-index: var(--zIndexTopbar);
  top: 0;
}

.container {
  width: 100%;
  height: var(--topbarHeightMobile);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.home {
  display: flex;
  /* padding: calc((var(--topbarHeight) - var(--Topbar_logoHeight)) / 2) 24px; */
  padding: 15px 12px 7px 12px;
}

/* Child component's size (and positioning) should be given here */

.buttonsWrapper,
.buttonsWrapperAuthenticated {
  display: grid;
  gap: 12px;
  margin-right: 12px;
}

.buttonsWrapper {
  grid-template-columns: 1fr 2fr;
  @media (--viewportMedium) {
    grid-template-columns: 2fr;
  }
}

.buttonsWrapperAuthenticated {
  grid-template-columns: 1fr 1fr 2fr;
  @media (--viewportMedium) {
    grid-template-columns: 1fr 2fr;
  }
}

.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  /* Layout */
  border-width: 0px;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 74px;
  height: 36px;
  background-color: var(--matterColorAnti);
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.iconsWrapper {
  background-color: var(--matterColorAnti);
  width: 36px;
}

.searchMenu {
  position: relative;
  background-color: var(--matterColorAnti);
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 8px;
  right: 8px;

  background-color: var(--marketplaceColor);
  border: 2px solid var(--matterColorAnti);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--matterColor);
}
.rootSearchIcon {
  stroke: var(--matterColor);
}

.inboxIcon,
.menuIcon,
.loginMenu {
  color: var(--marketplaceColorLight);
}

.inboxIcon {
  position: absolute;
  top: 13px;
  left: 8px;
  width: 18px;
  height: 16px;
}

.menuIcon {
  width: 15px;
  height: 17px;
  margin-right: 4px;
}

.avatar,
.loginMenu {
  width: 22px;
  height: 22px;
}

.searchIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 14px;
  height: 14px;
  color: var(--marketplaceColorLight);
}

.searchContainer {
  position: relative;
}

.mobileHelp {
  color: var(--matterColorAnti);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.reminderModalLinkButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: (--borderRadius);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: (--borderRadius);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoSearchWrapper {
  display: flex;
  align-items: center;
}

.allRentalsButton {
  display: flex;
  align-items: center;

  transition: var(--transitionStyleButton);
  background-color: var(--matterColorAnti);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  letter-spacing: 0;
  color: var(--marketplaceColorLight);
  padding: 8px 12px;
  border-radius: var(--borderRadius);
  text-decoration: none;
  margin-left: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.tabletMenuWrapper {
  position: fixed;
  top: var(--topbarHeightDesktop);
  right: 0;
  background-color: var(--matterColorLight);
  border-radius: 4px;
}
