.root {
  position: relative;
}

.inputBase {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColorDark);
  }

  /* Display checked box and checkmark when checked */
  &:checked + label .checked,
  &:checked + label .checkMark {
    display: inline;
  }
}

.input {
  composes: inputBase;

  &:checked + label .box,
  &:hover + label .box {
    stroke: var(--marketplaceColor);
  }

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .notChecked,
  &:hover + label .required,
  &:focus + label .notChecked,
  &:focus + label .required,
  &:checked + label .notChecked,
  &:checked + label .required {
    stroke: var(--matterColorDark);
  }
}

.inputBox {
  composes: inputBase;

  & + label .box {
    stroke: var(--marketplaceColorDark);
    fill: var(--matterColorLight);
  }

  /* Hide default box if checked */
  &:checked + label .box {
    display: none;
  }

  /* Highlight the borders if the checkbox is hovered */
  &:hover + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  & + label .box.success,
  &:hover + label .box.success {
    stroke: var(--successColor);
  }

  & + label .checked {
    fill: var(--marketplaceColor);
  }
  & + label .checkMark {
    fill: var(--marketplaceColorDark);
  }

  /* Hide checked box and checkmark by default */
  & + label .checked,
  & + label .checkMark {
    display: none;
  }

  /* successColor version */
  &:checked + label .checked.success {
    fill: var(--successColor);
  }
}

.label {
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -2px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checked {
  display: none;
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.notChecked {
  stroke: var(--matterColorAnti);
  &:hover {
    stroke: pink;
  }
}

.required {
  stroke: var(--attentionColor);
  &:hover {
    stroke: pink;
  }
}

.text {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.box {
  stroke: var(--matterColorAnti);
}
