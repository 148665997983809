@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
}

.email {
  margin-top: 17px;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-bottom: 48px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

/* Helper links */
.modalHelperLink {
  composes: marketplaceModalHelperLink from global;
  text-decoration: underline;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
  margin-top: 16px;
}
